//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
  switch (action.type) {
    case "UI": {
      return {
        category_parent: action.payload.category_parent,
        best_selling_products: action.payload.best_selling_products,
        brands: action.payload.brands,
        contact_us: action.payload.contact_us,
        menu: action.payload.menu,
        new_products: action.payload.new_products,
        sections: action.payload.sections,
        special_offer_products: action.payload.special_offer_products,
        tractor: action.payload.tractor,
        sliders: action.payload.sliders,
        lawn_mower: action.payload.lawn_mower,
        tow_wire: action.payload.tow_wire,
        articles: action.payload.articles,
        story: action.payload.story,
        diesel_generator: action.payload.diesel_generator,
        alternator: action.payload.alternator,
        certificates: action.payload.certificates,
      };
    }
    default: {
      return state;
    }
  }
};
